.alert {
    padding: 0.8rem;
    margin: 1rem 0;
    opacity: 0.9;
    background: var(--light-color);
    color: #333;
}

.btn-primary,
.bg-primary,
.badge-primary,
.alert-primary {
    background: var(--primary-color);
    color: #fff;
}

.btn-light,
.bg-light,
.badge-light,
.alert-light {
    background: var(--light-color);
    color: #333;
}

.btn-dark,
.bg-dark,
.badge-dark,
.alert-dark {
    background: var(--dark-color);
    color: #fff;
}

.btn-danger,
.bg-danger,
.badge-danger,
.alert-danger {
    background: var(--danger-color);
    color: #fff;
}

.btn-success,
.bg-success,
.badge-success,
.alert-success {
    background: var(--success-color);
    color: #fff;
}

.btn-white,
.bg-white,
.badge-white,
.alert-white {
    background: #fff;
    color: #333;
    border: #ccc solid 1px;
}
