.portfolioContainer1 {
    width: 100%;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0px;
    left: 0;
    right: 0;
    margin-top: 50px;
    overflow-y: scroll;
    transform: scale(0.6);
    border-radius: 0px;
    border: 2px solid black;
}
.portfolioContainerFull1 {
    width: 100%;
    height: 100%;
    margin: auto;
    /* position: absolute; */
    top: 0;
    bottom: 0px;
    left: 0;
    right: 0;
    overflow-y: scroll;
    background-color: white;
}
.portfolioContainer1 .portfolioBodyCont,
.portfolioContainerFull1 .portfolioBodyCont {
    position: absolute;
    background-color: white;
    width: 100%;
}
.portfolioContainer1 .portfolioPage1,
.portfolioContainerFull1 .portfolioPage1 {
    width: 100%;
    height: 100%;
    background-color: black;
    position: absolute;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.portfolioContainer1 .portfolioPage1Details,
.portfolioContainerFull1 .portfolioPage1Details {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.portfolioContainer1 .portfolioPage1SocialHolder,
.portfolioContainerFull1 .portfolioPage1SocialHolder {
    margin-top: 100px;
}
.portfolioContainer1 .portfolioUserImage,
.portfolioContainerFull1 .portfolioUserImage {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    margin: 40px;
}
.portfolioContainer1 .portfolioUserDetails,
.portfolioContainerFull1 .portfolioUserDetails {
    font-size: 35px;
    margin: 40px;
}
.portfolioContainer1 .portfolioUserName,
.portfolioContainerFull1 .portfolioUserName {
    font-weight: 700;
    letter-spacing: 1px;
}
.portfolioContainer1 .portfolioUserLabel,
.portfolioContainerFull1 .portfolioUserLabel {
    font-weight: 200;
    letter-spacing: 2px;
}
.portfolioContainer1 .miniLine,
.portfolioContainerFull1 .miniLine {
    width: 20%;
    height: 2px;
    background-color: #f1c40f;
    margin: auto;
    margin-top: 15px;
    margin-bottom: 15px;
}
/* ------------------------------------------------------- */
.portfolioContainer1 .portfolioBtn,
.portfolioContainerFull1 .portfolioBtn {
    width: 150px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 600;
    height: 45px;
    border: 2px solid #f1c40f;
    transition: 0.2s ease-out;
    color: #f1c40f;
    line-height: 45px;
    border-radius: 4px;
}
.portfolioContainer1 .portfolioBtn:hover,
.portfolioContainerFull1 .portfolioBtn:hover {
    color: black;
    background-color: #f1c40f;
}
.portfolioContainer1 .portfolioPage,
.portfolioContainerFull1 .portfolioPage {
    width: 100%;
    height: auto;
    padding-bottom: 40px;
}
/* ----------------------------------------------------------- */
.portfolioContainer1 .aboutPage,
.portfolioContainerFull1 .aboutPage {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #f1c40f;
    padding-bottom: 0px;
}
.portfolioContainer1 .portfolioPageSplit,
.portfolioContainerFull1 .portfolioPageSplit {
    width: 50%;
    min-height: 400px;
    height: auto;
}
.portfolioContainer1 .leftAbout,
.portfolioContainerFull1 .leftAbout {
    background-color: #f1c40f;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700;
}
.portfolioContainer1 .rightAbout,
.portfolioContainerFull1 .rightAbout {
    padding: 35px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}
.portfolioContainer1 .portfolioUserSummary,
.portfolioContainerFull1 .portfolioUserSummary {
    width: 60%;
    text-align: justify;
}
/* ---------------------------------------------------------- */
.portfolioContainer1 .workPage,
.portfolioContainerFull1 .workPage {
    background-color: #e6e6e6;
}
.portfolioContainer1 .portfolioPageTitle,
.portfolioContainerFull1 .portfolioPageTitle {
    text-align: center;
    padding-top: 40px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 26px;
}
.portfolioContainer1 .portfolioCardContainer,
.portfolioContainerFull1 .portfolioCardContainer {
    width: 80%;
    height: auto;
    /* background-color: red; */
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}
.portfolioContainer1 .portfolioCard,
.portfolioContainerFull1 .portfolioCard {
    width: 300px;
    padding: 20px;
    min-height: 400px;
    height: auto;
    margin-top: 30px;
}
/* .portfolioCardTi1* .portfolioCardTitle{
  background-color: red;
} */
.portfolioContainer1 .portfolioCardTitleMain,
.portfolioContainerFull1 .portfolioCardTitleMain {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 500;
}
.portfolioContainer1 .portfolioCardTitleSub,
.portfolioContainerFull1 .portfolioCardTitleSub {
    text-transform: capitalize;
    font-weight: 300;
    font-size: 18px;
}
.portfolioContainer1 .miniCardLine,
.portfolioContainerFull1 .miniCardLine {
    margin-right: auto;
    margin-left: 0px;
}
.portfolioContainer1 .portfolioCardContent,
.portfolioContainerFull1 .portfolioCardContent {
    width: 100%;
    text-align: justify;
}
.portfolioContainer1 .portfolioCardWebsite,
.portfolioContainerFull1 .portfolioCardWebsite {
    margin-top: 20px;
    font-size: 15px;
}
.portfolioContainer1 .portfolioCardWebsite span,
.portfolioContainerFull1 .portfolioCardWebsite span {
    font-weight: 600;
    text-transform: uppercase;
}
.portfolioContainer1 .portfolioCardWebsite a,
.portfolioContainerFull1 .portfolioCardWebsite a {
    color: #f1c40f;
    text-decoration: none;
}
.portfolioContainer1 .portfolioCardDateContainer,
.portfolioContainerFull1 .portfolioCardDateContainer {
    width: 100%;
    height: 50px;
    border: 2px solid #f1c40f;
    line-height: 50px;
    text-align: center;
    margin: auto;
    margin-top: 20px;
    font-weight: 600;
    background-color: #f1c40f;
}
/* -------------------------------------------------------------- */
.portfolioContainer1 .volunteerPage,
.portfolioContainerFull1 .volunteerPage {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 0px;
    background-color: #f1c40f;
    border-bottom: 5px solid black;
}
.portfolioContainer1 .rightVolunteer,
.portfolioContainerFull1 .rightVolunteer {
    background-color: #f1c40f;
    color: white;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 40px;
    padding-top: 40px;
}
.portfolioContainer1 .leftVolunteer,
.portfolioContainerFull1 .leftVolunteer {
    display: flex;
    width: 70%;
    flex-direction: column;
    background-color: rgba(240, 240, 240, 1);
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
}
.portfolioContainer1 .portfolioVolunteerExpansionPanel,
.portfolioContainerFull1 .portfolioVolunteerExpansionPanel {
    width: 80%;
    margin: auto;
    margin-top: 3px;
    margin-bottom: 3px;
}
.portfolioContainer1
    .portfolioVolunteerExpansionPanel
    .portfolioCardDateContainer,
.portfolioContainerFull1
    .portfolioVolunteerExpansionPanel
    .portfolioCardDateContainer {
    padding: 0px 12px;
    width: auto;
    margin-left: 0px;
    margin-right: auto;
}
.portfolioContainer1 .portfolioExpansionPanelTitle,
.portfolioContainerFull1 .portfolioExpansionPanelTitle {
    flex-basis: 60%;
    flex-shrink: 0;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
}
.portfolioContainer1 .portfolioExpansionPanelSubTitle,
.portfolioContainerFull1 .portfolioExpansionPanelSubTitle {
    color: #f1c40f;
    font-size: 13px;
    text-transform: capitalize;
}
.portfolioContainer1 .portfolioExpansionPanelDetails,
.portfolioContainerFull1 .portfolioExpansionPanelDetails {
    display: flex;
    flex-direction: column;
}
.portfolioContainer1 .portfolioExpansionPanelWebsite,
.portfolioContainerFull1 .portfolioExpansionPanelWebsite {
    margin-top: 0px;
}
.portfolioContainer1 .portfolioExpansionPanelWebsite span,
.portfolioContainerFull1 .portfolioExpansionPanelWebsite span {
    font-weight: 600;
    text-transform: uppercase;
}
.portfolioContainer1 .portfolioExpansionPanelWebsite a,
.portfolioContainerFull1 .portfolioExpansionPanelWebsite a {
    color: #f1c40f;
    text-decoration: none;
}
/* ------------------------------------------------------------ */
.portfolioContainer1 .portfolioFlatContainer,
.portfolioContainerFull1 .portfolioFlatContainer {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-bottom: 30px;
}
.portfolioContainer1 .portfolioFlat,
.portfolioContainerFull1 .portfolioFlat {
    width: 300px;
    min-height: 380px;
    height: auto;
    padding: 40px;
    border-radius: 20px;
    border: 1px solid #f1c40f;
    margin-top: 40px;
    transition: 0.2s ease-out;
}
.portfolioContainer1 .portfolioFlat:hover,
.portfolioContainerFull1 .portfolioFlat:hover {
    background-color: #f1c40f;
    color: white;
}
.portfolioContainer1 .portfolioFlat:hover .portfolioFlatSubTitle,
.portfolioContainerFull1 .portfolioFlat:hover .portfolioFlatSubTitle {
    color: black;
}
.portfolioContainer1 .portfolioFlat:hover .portfolioCardDateContainer,
.portfolioContainerFull1 .portfolioFlat:hover .portfolioCardDateContainer {
    background-color: black;
}
.portfolioContainer1 .portfolioFlatSubTitle,
.portfolioContainerFull1 .portfolioFlatSubTitle {
    transition: 0.2s ease-out;
    color: #f1c40f;
    text-transform: capitalize;
    font-weight: 400;
    margin-top: 10px;
}
.portfolioContainer1 .portfolioFlatContentSummary,
.portfolioContainerFull1 .portfolioFlatContentSummary {
    margin-top: 10px;
}
/* ---------------------------------------------------- */
.portfolioContainer1 .extrasPage,
.portfolioContainerFull1 .extrasPage {
    background-color: rgba(238, 238, 238, 0.5);
    padding-bottom: 20px;
}
.portfolioContainer1 .extrasPage .miniLine,
.portfolioContainerFull1 .extrasPage .miniLine {
    margin-top: 30px;
}
.portfolioContainer1 .floodFill,
.portfolioContainerFull1 .floodFill {
    background-color: black;
    color: white;
    height: 50px;
    line-height: 50px;
    padding-bottom: 40px;
}
.portfolioContainer1 .portfolioExtraCard,
.portfolioContainerFull1 .portfolioExtraCard {
    display: flex;
    margin-top: 50px;
    width: 500px;
    flex-direction: row;
    height: auto;
    margin-left: 20px;
    margin-right: 20px;
    background-color: #e6e6e6;
}
.portfolioContainer1 .portfolioExtraDetails,
.portfolioContainerFull1 .portfolioExtraDetails {
    display: flex;
    flex-direction: column;
    width: 300px;
}
.portfolioContainer1 .portfolioExtraContent,
.portfolioContainerFull1 .portfolioExtraContent {
    display: flex;
    flex-direction: column;
}
.portfolioContainer1 .chip,
.portfolioContainerFull1 .chip {
    margin-top: 10px;
}
.portfolioContainer1 .portfolioExtraImage,
.portfolioContainerFull1 .portfolioExtraImage {
    height: auto;
    width: 200px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.portfolioContainer1 .miniAwardImage,
.portfolioContainerFull1 .miniAwardImage {
    background-image: url('../images/award.jpg');
}
.portfolioContainer1 .miniPublicationImage,
.portfolioContainerFull1 .miniPublicationImage {
    background-image: url('../images/publish.jpg');
}
.portfolioContainer1 .miniLanguageImage,
.portfolioContainerFull1 .miniLanguageImage {
    background-image: url('../images/language.png');
}
.portfolioContainer1 .miniSkillImage,
.portfolioContainerFull1 .miniSkillImage {
    background-image: url('../images/skill.jpg');
}
.portfolioContainerFull1 .portfolioAwardCompany span,
.portfolioContainer1 .portfolioAwardDate span,
.portfolioContainerFull1 .portfolioAwardDate span {
    font-weight: 600;
}
.portfolioContainerFull1 .portfolioPublishCompany span,
.portfolioContainerFull1 .portfolioPublishDate span,
.portfolioContainer1 .portfolioPublishWebsite span,
.portfolioContainerFull1 .portfolioPublishWebsite span {
    font-weight: 600;
}
.portfolioContainer1 .portfolioPublishWebsite a,
.portfolioContainerFull1 .portfolioPublishWebsite a {
    color: #f1c40f;
    text-decoration: none;
}

/* --------------------------------------------- */
/* .contactPag, /* 1actPage{
} */
.portfolioContainer1 .contactPage .portfolioPageTitle,
.portfolioContainerFull1 .contactPage .portfolioPageTitle {
    padding-bottom: 20px;
}
.portfolioContainer1 .portfolioContactCard,
.portfolioContainerFull1 .portfolioContactCard {
    width: 360px;
    border-radius: 15px;
    margin: auto;
    margin-top: 20px;
    border: 2px solid black;
    height: auto;
    padding: 30px;
}
.portfolioContainer1 .portfolioContactCardAddress,
.portfolioContainerFull1 .portfolioContactCardAddress {
    text-align: center;
    text-transform: capitalize;
    line-height: 24px;
}
.portfolioContainer1 .portfolioContactCardAddress span,
.portfolioContainerFull1 .portfolioContactCardAddress span {
    color: #f1c40f;
}
.portfolioContainer1 .portfolioContactCard li,
.portfolioContainerFull1 .portfolioContactCard li {
    margin-top: 10px;
}
.portfolioContainer1 .portfolioContactCardInfoTitle,
.portfolioContainerFull1 .portfolioContactCardInfoTitle {
    font-weight: 600;
    color: #f1c40f;
}
.portfolioContainer1 .portfolioContactSocialCard,
.portfolioContainerFull1 .portfolioContactSocialCard {
    text-align: center;
    color: #f1c40f;
    background-color: black;
}
.portfolioContainer1 .noProf,
.portfolioContainerFull1 .noProf {
    text-align: center;
    text-transform: capitalize;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    font-weight: 300;
}
.portfolioContainer1 .noProfLarge,
.portfolioContainerFull1 .noProfLarge {
    font-size: 30px;
    font-weight: 600;
}
.portfolioContainer1 .noProf span,
.portfolioContainerFull1 .noProf span {
    font-weight: 700;
    text-transform: uppercase;
    /* font-style: italic; */
    cursor: pointer;
}
