.loginPageContainer {
    background-image: url('https://images.unsplash.com/photo-1432821596592-e2c18b78144f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80');
    background-size: cover;
    background-position: right;
    top: 55px;
    position: fixed;
    left: 0px;
    right: 0px;
    height: calc(100% - 55px);
    overflow-y: scroll;
    bottom: 0px;
}
.pageOverlay {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: white;
    padding-top: 40px;
    padding-bottom: 40px;
    overflow-x: hidden;
    min-height: calc(100% - 80px);
    height: auto;
}
.lgnBtnCont {
    padding-top: 0px;
}
.title {
    text-transform: uppercase;
    font-weight: 600;
}
.formCont form {
    display: flex;
    flex-direction: column;
}
.loginFormCont form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 140px;
    text-indent: 40px;
}
.loginFormCont input {
    padding: 10px;
    margin: 0px;
    height: 25px;
    border-radius: 5px;
}
.row {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    height: 36px;
    line-height: 36px;
    justify-content: space-around;
}
.rowTwo {
    width: 96%;
    margin: auto;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.rowTwo input {
    margin: 0px;
}

.row input {
    width: 45%;
    margin-top: 0px;
}

input {
    outline: none;
    width: 95.3%;
    margin: auto;
    margin-top: 20px;
    height: 30px;
    font-weight: 500;
    font-size: 13px;
    line-height: 30px;
    text-indent: 10px;
    border: 2px solid black;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input:hover {
    border: 2px solid #3d40d8;
    /* background-color: #3d40d8; */
    /* color: white; */
}
input:focus {
    border: 2px solid #3d40d8;
}
.loginFormCont input {
    width: 300px !important;
    font-size: 14px;
    background-color: rgba(230, 230, 230, 0.8);
}
/* input:hover::placeholder{
  color: rgba(255,255,255,0.8);
} */
.btn {
    width: 200px;
    height: 50px;
    cursor: pointer;
    font-weight: 500;
    margin: auto;
    margin-top: 40px;
    border: 2.5px solid #3d40d8;
    font-size: 16px;
    color: #3d40d8;
    text-align: center;
    background-color: transparent;
    /* line-height: 40px; */
    transition: 0.2s ease-out;
    outline: none;
    display: block;
}
.btn:hover {
    background-color: #3d40d8;
    color: white;
    border-radius: 10px;
}
.dropDownRow {
    width: 96%;
    margin: auto;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.dropdownContainer {
    /* width: 28.75%; */
    width: 32%;
    height: 30px;
    line-height: 30px;
    text-indent: 10px;
    cursor: pointer;
    background-color: white;
    border: 2px solid black;
}
.dropdownContainer:hover {
    border: 2px solid #3d40d8;
}
.dropdownContainer:hover .dropdownDrop {
    transform: scaleY(1);
}
.dropdownDisp span {
    color: #757575;
}
.dropdownDisp {
    font-size: 13px;
}
.dropdownDrop {
    height: 150px;
    background-color: rgba(230, 230, 230, 0.93);
    overflow-y: scroll;
    font-size: 13px;
    width: 100%;
    margin-top: 2px;
    transform: scaleY(0);
    text-indent: 10px;
    transition: 0.09s ease-out;
    margin-left: -2px;
    border-right: 2px solid #3d40d8;
    border-left: 2px solid #3d40d8;
    border-bottom: 2px solid #3d40d8;
}
.dropdownElement:hover {
    background-color: #3d40d8;
    color: white;
}
.secBtnCont {
    margin-top: 35px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.headerLogoutBtn {
    margin-left: auto;
    padding: 6px 25px;
    border-radius: 20px;
    color: #e74c3c;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    border: 2px solid #e74c3c;
    transition: 0.2s ease-out;
    font-size: 13px;
    font-weight: 600;
}
.headerLogoutBtn:hover {
    background-color: #e74c3c;
    color: white;
}
.bottomButtonContainer {
    position: fixed;
    bottom: 10px;
    right: 10px;
    padding: 5px;
    display: flex;
    flex-direction: column;
}
