.body {
    background-color: #8f8f93;
}
.portfolioContainerFull4 {
    width: 100%;
    height: 100%;
    margin: auto;
    /* position: absolute; */
    top: 0;
    bottom: 0px;
    left: 0;
    right: 0;
    overflow-y: scroll;
    /* background-color: white; */
}

.portfolioContainerFull4 .portfolioBodyCont {
    position: absolute;
    background-color: white;
    width: 100%;
}

.portfolioContainerFull4 .portfolioPage1 {
    width: 100%;
    height: 100%;
    background-color: none;
    position: absolute;
    color: #000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.portfolioContainerFull4 .portfolioPage1Details {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.portfolioContainerFull4 .portfolioPage1SocialHolder {
    margin-top: 100px;
}

.portfolioContainerFull4 .portfolioUserImage {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    margin: 40px;
}

.portfolioContainerFull4 .portfolioUserDetails {
    font-size: 35px;
    margin: 40px;
}

.portfolioContainerFull4 .portfolioUserName {
    font-weight: 900;
    letter-spacing: 1px;
}

.portfolioContainerFull4 .portfolioUserLabel {
    font-weight: 600;
    letter-spacing: 2px;
}

.portfolioContainerFull4 .miniLine {
    width: 20%;
    height: 2px;
    background-color: #b22222;
    margin: auto;
    margin-top: 15px;
    margin-bottom: 15px;
}

/* ------------------------------------------------------- */
.portfolioContainerFull4 .portfolioBtn {
    font-weight: 600;
    width: 200px;
    text-align: center;
    cursor: pointer;
    font-weight: 600;
    height: 45px;
    border-radius: 50px;
    background: linear-gradient(145deg, #99999d, #818184);
    box-shadow: 23px 23px 58px #7c7c80, -23px -23px 58px #a2a2a6;
    transition: 0.2s ease-out;
    color: #000000;
    line-height: 45px;
    border-radius: 15px;
}

.portfolioContainerFull4 .portfolioBtn:hover {
    font-weight: 600;
    width: 200px;
    text-align: center;
    cursor: pointer;
    font-weight: 400;
    height: 45px;
    background: #8f8f93;
    box-shadow: 23px 23px 58px #7c7c80, -23px -23px 58px #a2a2a6;
    transition: 0.2s ease-out;
    color: #000000;
    line-height: 45px;
    border-radius: 15px;
}

.portfolioContainerFull4 .portfolioBtn:active {
    font-weight: 600;
    width: 200px;
    text-align: center;
    cursor: pointer;
    font-weight: 400;
    height: 45px;
    background: linear-gradient(145deg, #818184, #99999d);
    box-shadow: 23px 23px 58px #7c7c80, -23px -23px 58px #a2a2a6;
    transition: 0.2s ease-out;
    color: #000000;
    line-height: 45px;
    border-radius: 15px;
}
.portfolioContainerFull4 .portfolioBtn:focus {
    outline: 0;
}

.portfolioContainerFull4 .portfolioPage {
    width: 100%;
    height: auto;
    padding-bottom: 40px;
}

/* ----------------------------------------------------------- */
.portfolioContainerFull4 .aboutPage {
    background-color: #aeaeb2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 0px;
}

.portfolioContainerFull4 .portfolioPageSplit {
    width: 50%;
    min-height: 400px;
    height: auto;
}

.portfolioContainerFull4 .leftAbout {
    width: 50%;
    background-color: #aeaeb2;
    color: #000000;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 2em;
    font-weight: 700;
    margin-left: auto;
}

.portfolioContainerFull4 .aboutCard {
    opacity: 0.8;
    background-color: #081b33;
    padding: 50px;
    border-radius: 20px;
    background: linear-gradient(45deg, #bababe, #9d9da0);
    box-shadow: 23px -23px 58px #97979b, -23px 23px 58px #c5c5c9;
}

.portfolioContainerFull4 .rightAbout {
    color: #000000;
    background-color: #c7c7cc;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    padding: 35px 0px;
}

.portfolioContainerFull4 .portfolioUserSummary {
    width: 60%;
    border-radius: 20px;
    padding: 20px;
    border-radius: 20px;
    background: linear-gradient(45deg, #d5d5da, #b3b3b8);
    box-shadow: 14px -14px 52px #adadb1, -14px 14px 52px #e1e1e7;
    z-index: 1;
}

/* ---------------------------------------------------------- */
.portfolioContainerFull4 .workPage {
    background-color: #e6e6e6;
}

.portfolioContainerFull4 .portfolioPageTitle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: #000000;
    text-align: center;
    padding-top: 40px;
    font-weight: 600;
    font-size: 26px;
    background-color: #d4d1d6;
    text-align: center;
    padding-bottom: 40px;
}

.portfolioContainerFull4 .portfolioPage {
    padding-bottom: 0;
}

.portfolioContainerFull4 .portfolioPage .portfolioEducationPage {
    background-color: #d4d1d6;
    z-index: 2;
}

.portfolioContainerFull4 .titleCard {
    z-index: 2;
    padding: 10px;
    width: 200px;
    border-radius: 20px;
    background: #d4d1d6;
    box-shadow: 14px -14px 52px #b8b6ba, -14px 14px 52px #f0ecf2;
}

.portfolioContainerFull4 .portfolioCardContainer {
    width: 80%;
    height: auto;
    /* background-color: red; */
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}
.portfolioCardContainer .portfolioCardWorkContainer {
    border-radius: 20px;
    background: #e5e5ea;
    background: linear-gradient(45deg, #f5f5fa, #ceced3);
    box-shadow: 14px -14px 52px #c7c7cc, -14px 14px 52px #ffffff;
}

.portfolioContainerFull4 .workCard {
    background-color: #15253f;
    color: #000000;
    width: 300px;
    min-height: 380px;
    height: auto;
    padding: 40px;
    border-radius: 20px;
    border-radius: 20px;
    background: #e5e5ea;
    background: linear-gradient(45deg, #d4d4da, #b2b2b8);
    box-shadow: 14px -14px 52px #acacb1, -14px 14px 52px #e0e0e7;
    transition: 0.2s ease-out;
}

.portfolioContainerFull4 .portfolioCard {
    width: 300px;
    padding: 20px;
    min-height: 400px;
    height: auto;
    margin-top: 30px;
}

/* .portfolioCardTitle{
  background-color: red;
} */
.portfolioContainerFull4 .portfolioCardTitleMain {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 500;
}

.portfolioContainerFull4 .portfolioCardTitleSub {
    text-transform: capitalize;
    font-weight: 300;
    font-size: 18px;
}

.portfolioContainerFull4 .miniCardLine {
    margin-right: auto;
    margin-left: 0px;
}

.portfolioContainerFull4 .portfolioCardContent {
    width: 100%;
    text-align: justify;
}

.portfolioContainerFull4 .portfolioCardWebsite {
    margin-top: 20px;
    font-size: 15px;
}

.portfolioContainerFull4 .portfolioCardWebsite span {
    font-weight: 600;
    text-transform: uppercase;
}

.portfolioContainerFull4 .portfolioCardWebsite a {
    color: #b22222;
    text-decoration: none;
}

.portfolioContainerFull4 .portfolioCard:hover .portfolioCardWebsite a {
    color: #000000;
    text-decoration: none;
}

.portfolioContainerFull4 .portfolioCardDateContainer {
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    margin: auto;
    margin-top: 20px;
    font-weight: 600;
    background-color: #8f8f93;
}

/* -------------------------------------------------------------- */
.portfolioContainerFull4 .volunteerPage {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 0px;
    background-color: #d4d1d6;
}

.portfolioContainerFull4 .rightVolunteer {
    text-align: center;
    background-color: #aeaeb2;
    color: #000000;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2vw;
    font-weight: 700;
    padding-bottom: 40px;
    padding-top: 40px;
}

.portfolioContainerFull4 .leftVolunteer {
    display: flex;
    width: 70%;
    flex-direction: column;
    background-color: #d4d1d6;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
}

.portfolioContainerFull4
    .portfolioVolunteerExpansionPanel
    .portfolioCardDateContainer {
    padding: 0px 12px;
    width: auto;
    margin-left: 0px;
    margin-right: auto;
}
.portfolioContainerFull4 .portfolioVolunteerExpansionPanel {
    width: 80%;
    margin: auto;
    margin-top: 3px;
    margin-bottom: 3px;
}

.portfolioContainerFull4 .expansionSummary {
    background: #c7c7cc !important;
}

.portfolioContainerFull4 .expansionExpanded {
    background-color: #f2f2f7 !important;
    color: #000000;
}

.portfolioContainerFull4 .portfolioExpansionPanelTitle {
    color: #000000 !important;
    flex-basis: 60%;
    flex-shrink: 0;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
}

.portfolioContainerFull4 .expandIcon {
    color: #000000 !important;
}

.portfolioContainerFull4 .portfolioExpansionPanelSubTitle {
    color: #152642;
    font-size: 13px;
    text-transform: capitalize;
}

.portfolioContainerFull4 .portfolioExpansionPanelDetails {
    border-radius: 10px !important;
    display: flex;
    flex-direction: column;
}

.portfolioContainerFull4 .portfolioExpansionPanelWebsite {
    margin-top: 0px;
}

.portfolioContainerFull4 .portfolioExpansionPanelWebsite span {
    font-weight: 600;
    text-transform: uppercase;
}

.portfolioContainerFull4 .portfolioExpansionPanelWebsite a {
    color: #152642;
    text-decoration: none;
}

.portfolioContainerFull4 .portfolioWorkCard {
    background-color: #15253f;
    color: #000000;
    width: 300px;
    min-height: 380px;
    height: auto;
    padding: 40px;
    border-radius: 20px;
    border-radius: 20px;
    background: #e5e5ea;
    background: linear-gradient(45deg, #d4d4da, #b2b2b8);
    box-shadow: 14px -14px 52px #acacb1, -14px 14px 52px #e0e0e7;
    transition: 0.2s ease-out;
}

.portfolioContainerFull4 .portfolioCardContainer {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-top: 30px;
    padding-bottom: 30px;
    background: #c6c6cc;
    transition: 0.2s ease-out;
}

.portfolioContainerFull4 .portfolioWorkCard:hover {
    background: #697280;
    opacity: 1;
    color: #def2f1;
    transition: 0.2s ease-out;
}
.portfolioContainerFull4 .portfolioWorkCard:hover .miniCardLine {
    background-color: #000000;
    transition: 0.2s ease-out;
}

.portfolioContainerFull4 .portfolioWorkCard:hover .portfolioCardDateContainer {
    background-color: #4a4a53;
}

/* ------------------------------------------------------------ */
.portfolioContainerFull4 .portfolioFlatContainer {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-top: 30px;
    padding-bottom: 30px;
    background: #c6c6cc;
}

.portfolioContainerFull4 .portfolioFlat {
    background-color: #15253f;
    color: #000000;
    width: 300px;
    min-height: 380px;
    height: auto;
    padding: 40px;
    border-radius: 20px;
    border-radius: 20px;
    background: #e5e5ea;
    background: linear-gradient(45deg, #d4d4da, #b2b2b8);
    box-shadow: 14px -14px 52px #acacb1, -14px 14px 52px #e0e0e7;
    transition: 0.2s ease-out;
}

.portfolioContainerFull4 .portfolioFlat:hover {
    background: #697280;
    opacity: 1;
    color: #def2f1;
}

.portfolioContainerFull4 .portfolioFlat:hover .portfolioFlatSubTitle {
    color: black;
}

.portfolioContainerFull4 .portfolioFlat:hover .portfolioCardDateContainer {
    background-color: #4a4a53;
    border: none;
    color: #e5e5ea;
}

.portfolioContainerFull4 .portfolioFlatSubTitle {
    transition: 0.2s ease-out;
    color: #152642;
    text-transform: capitalize;
    font-weight: 400;
    margin-top: 10px;
}

.portfolioContainerFull4 .portfolioFlatContentSummary {
    margin-top: 10px;
}

/* ---------------------------------------------------- */
.portfolioContainerFull4 .portfolioExtrasContainer {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #c6c6cc;
}

.portfolioContainerFull4 .extrasPage {
    background-color: #c6c6cc;
    padding-bottom: 30px;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.portfolioContainerFull4 .floodFill {
    display: flex;
    width: 200px;
    height: 30px;
    border-radius: 50px;
    background: #c6c6cc;
    box-shadow: 14px -14px 52px #b4b4ba, -14px 14px 52px #d8d8de;
    padding-top: 15px;
    padding-bottom: 15px;
}

.portfolioContainerFull4 .portfolioExtraCard {
    color: #b22222 !important;
    display: flex;
    margin-top: 50px;
    width: 500px;
    flex-direction: row;
    height: auto;
    margin-left: 20px;
    margin-right: 20px;
    background-color: #000000 !important;
}
.portfolioContainerFull4 .portfolioExtraCard h5 {
    font-weight: 300;
    color: #000000 !important;
}

.portfolioContainerFull4 .portfolioExtraDetails {
    background-color: #aeaeb2 !important;
    color: #b22222 !important;
    display: flex;
    flex-direction: column;
    width: 300px;
}

.portfolioContainerFull4 .portfolioExtraContent {
    color: #b22222 !important;
    display: flex;
    flex-direction: column;
}

.portfolioContainerFull4 .chip {
    background: rgba(142, 172, 189, 0.2) !important;
    border: 2px solid #38576d !important;
    margin-top: 10px;
}

.portfolioContainerFull4 .portfolioExtraImage {
    height: auto;
    width: 200px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.portfolioContainerFull4 .miniAwardImage {
    background-image: url('../images/award.jpg');
}

.portfolioContainerFull4 .miniPublicationImage {
    background-image: url('../images/publish.jpg');
}

.portfolioContainerFull4 .miniLanguageImage {
    background-image: url('../images/language.png');
}

.portfolioContainerFull4 .miniSkillImage {
    background-image: url('../images/skill.jpg');
}

.portfolioContainerFull4 .portfolioAwardCompany span,
.portfolioContainerFull4 .portfolioAwardDate span {
    font-weight: 600;
}

.portfolioContainerFull4 .portfolioPublishCompany span,
.portfolioContainerFull4 .portfolioPublishDate span,
.portfolioContainerFull4 .portfolioPublishWebsite span {
    font-weight: 600;
}

.portfolioContainerFull4 .portfolioPublishWebsite a {
    color: #152642;
    text-decoration: none;
}

/* --------------------------------------------- */
.portfolioContainerFull4 .contactPage {
    background: #c6c6cc;
    padding-bottom: 40px;
}

.portfolioContainerFull4 .portfolioContactCard {
    background: #e5e5ea;
    background: linear-gradient(45deg, #d4d4da, #b2b2b8);
    box-shadow: 14px -14px 52px #acacb1, -14px 14px 52px #e0e0e7;
    width: 360px;
    border-radius: 15px;
    margin: auto;
    margin-top: 20px;
    height: auto;
    padding: 30px;
}

.portfolioContainerFull4 .portfolioContactCardAddress {
    text-align: center;
    text-transform: capitalize;
    line-height: 24px;
}

.portfolioContainerFull4 .portfolioContactCardAddress span {
    color: #152642;
}

.portfolioContainerFull4 .portfolioContactCard li {
    margin-top: 10px;
}

.portfolioContainerFull4 .portfolioContactCardInfoTitle {
    font-weight: 600;
    color: #152642;
}

.portfolioContainerFull4 .portfolioContactSocialCard {
    text-align: center;
    color: #152642;
    background-color: black;
}

.portfolioContainerFull4 .noProf {
    text-align: center;
    text-transform: capitalize;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    font-weight: 300;
}

.portfolioContainerFull4 .noProfLarge {
    font-size: 30px;
    font-weight: 600;
}

.portfolioContainerFull4 .noProf span {
    font-weight: 700;
    text-transform: uppercase;
    /* font-style: italic; */
    cursor: pointer;
}

.portfolioContainerFull4 .particles-bg {
    width: 100%;
    height: 100%;
    background-color: #8f8f93;
    position: absolute;
    z-index: -1;
}
.portfolioContainerFull4 .awardDialog .awardDialogTitle h2 {
    font-weight: 900 !important;
    color: #000000;
}
.portfolioContainerFull4
    .awardDialog
    .awardDialogTitle
    h2
    p
    .portfolioAwardCompany {
    font-weight: 900 !important;
    color: #4095bb;
}
.portfolioContainerFull4
    .awardDialog
    .awardDialogTitle
    h2
    p
    .portfolioAwardDate {
    font-weight: 900 !important;
    color: #4095bb;
}
.portfolioContainerFull4 .awardDialog .awardDialogTitle {
    font-weight: 900;
}
