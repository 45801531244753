.fullScreenInside {
    height: auto;
    right: 0%;
    left: 0%;
    display: flex;
    text-align: left;
    top: 100px;
    bottom: 50px;
    justify-content: center;
    padding-left: 30px;
    padding-right: 30px;
    flex-direction: column;
}
.buttonRow {
    width: auto;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 20px;
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    /* justify-content: center; */
    flex-wrap: wrap;
    align-items: center;
}
.buttonColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
