.homePageTitle {
    font-size: 45px;
    font-weight: 200;
    letter-spacing: 1px;
    font-style: italic;
    text-align: center;
    margin-top: 20px;
}

.portfolioContainer {
    width: 100%;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0px;
    left: -25px;
    right: 0px;
    margin-top: 50px;
    overflow-y: scroll;
    transform: scale(0.6);
    border-radius: 20px;
    border: 2px solid black;
}

.portfolioHomePreviewNavContainer {
    text-align: center;
    /* background-color: white; */
    /* width: inherit; */
    /* border-radius: 50px; */
    /* width: 100%; */
    /* left: 50%; */
    margin-bottom: 25px;
    margin-right: 25px;
    /* transform: translateX(-60%); */
    /* position: absolute; */
    /* bottom: 15%; */
}
.portfolioHomeActionButtons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btnRowHome {
    /* text-align: center; */
    position: absolute;
    display: flex;
    flex-direction: column;
    /* left: 0px; */
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-decoration: none;
    /* right: 0px; */
    bottom: 4%;
}
.remDec {
    text-decoration: none;
}
.loadingIcon {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
}
