.confirmationRootContainer {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 10000000;
}
.confirmationBackgroundOverlay {
    background-color: rgba(240, 240, 240, 0.2);
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}
.confirmationContentContainer {
    width: 350px;
    height: auto;
    padding: 0px 30px;
    padding-top: 20px;
    background-color: rgba(250, 250, 250, 0.3);
    border: 1px solid rgba(0, 0, 0, 0.418);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 10px;
}
@supports not (backdrop-filter: blur(10px)) {
    .confirmationBackgroundOverlay {
        background-color: rgba(240, 240, 240, 0.5);
    }
    .confirmationContentContainer {
        background-color: rgba(250, 250, 250, 1);
        z-index: 100000000000000;
    }
}
.confirmationDetailContainer {
    font-size: 14px;
}
.confirmationActionArea {
    display: flex;
    flex-direction: row;
    margin: 20px 0px;
    justify-content: space-evenly;
}
.confirmationMiniLine {
    width: 30px;
    height: 6px;
    border-radius: 3px;
    background-color: #3d40d8;
    margin: 10px 0px;
}
.confirmationBtn {
    padding: 8px 40px;
    background-color: transparent;
    border: 1.5px solid #3d40d8;
    font-size: 12px;
    margin-right: auto;
    font-weight: 600;
    color: #3d40d8;
    cursor: pointer;
    outline: none;
    border-radius: 20px;
    transition: 0.2s ease-out;
}
.confirmationBtn:hover {
    background-color: #3d40d8;
    color: white;
}
.confirmationBtnDanger {
    border: 1.5px solid #e74c3c;
    background-color: #e74c3c;
    color: white;
}
.confirmationBtnDanger:hover {
    background-color: #c0392b;
    border: 1.5px solid #c0392b;
}
